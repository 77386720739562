import store from '@/store/index.js';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

function checkHas(str, arr) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].path === str) {
            return i;
        }
    }
}

function setRouter(fatherArr, sonArr) {
    const arr = fatherArr;
    if (sonArr.length > 0) {
        for (let i = 0; i < sonArr.length; i++) {
            const index = checkHas(sonArr[i].path, fatherArr);
            if (index > -1) {
                if (arr[index].children) {
                    arr[index].children = sonArr[i].children;
                }
                arr[index] = sonArr[i];
            } else {
                arr.push(sonArr[i]);
            }
        }
    }
    return arr;
}

function getRouter() {
    // 【添加代码】解决vue-router在3.0版本以上重复点路由报错
    const originalPush = VueRouter.prototype.push;
    VueRouter.prototype.push = function push(location) {
        return originalPush.call(this, location).catch((err) => err);
    };
    const defaultRouteList = require('@/router/data.js').default.defaultRouteList;
    // 项目完成后，统一形成路由同步获取路由
    const theme = store.state.theme;
    const router_domain_url = store.state.router_domain_url;
    let indexRouterSon = [];
    let publicRouterSon = [];
    let personRouterSon = [];
    let file = '';
    const indexRouterlist = defaultRouteList[0].children;
    const personRouterlist = defaultRouteList[1].children;
    const publicRouterlist = defaultRouteList[2].children;
    try {
        file = require(`@/views/theme/${theme}/router/index.js`);
        if ('indexRouterlist' in file.default) {
            indexRouterSon = file.default.indexRouterlist;
        }
        if ('publicRouterlist' in file.default) {
            publicRouterSon = file.default.publicRouterlist;
        }
        if ('personRouterlist' in file.default) {
            personRouterSon = file.default.personRouterlist;
        }
    } catch (e) {
        try {
            file = require(`@/views/customization/${router_domain_url}/router/index.js`);
            if ('indexRouterlist' in file.default) {
                indexRouterSon = file.default.indexRouterlist;
            }
            if ('publicRouterlist' in file.default) {
                publicRouterSon = file.default.publicRouterlist;
            }
            if ('personRouterlist' in file.default) {
                personRouterSon = file.default.personRouterlist;
            }
        } catch (e) {
            indexRouterSon = [];
            publicRouterSon = [];
            personRouterSon = [];
        }
    }
    const indexRouterlistS = setRouter(indexRouterlist, indexRouterSon);
    const personRouterlistS = setRouter(personRouterlist, personRouterSon);
    const publicRouterlistS = setRouter(publicRouterlist, publicRouterSon);
    defaultRouteList[0].children = indexRouterlistS;
    defaultRouteList[1].children = personRouterlistS;
    defaultRouteList[2].children = publicRouterlistS;
    const router = new VueRouter({
        mode: 'history',
        scrollBehavior: () => ({ y: 0 }),
        routes: defaultRouteList,
    });
    if (store.state.config) {
        document.title =store.state.config.topTitle.value
    }
    router.beforeEach((to, from, next) => {
        setTimeout(function () {
            if (window._hmt) {
                if (to.path) {
                    window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
                }
            }
            if (window.ga) {
                window.ga('set', 'page', to.fullPath) // 你可能想根据请求参数添加其他参数，可以修改这里的 to.fullPath
                window.ga('send', 'pageview')
            }
        }, 15000);
        if (navigator.userAgent.indexOf('MSIE') > -1) {
            // path:不兼容组件所在路由
            alert('IE10及以下浏览器不能使用该框架，请使用更高版本的浏览器查看');
        } else {

            if (to.matched.length == 0) {
                // 如果未匹配到路由,就到error页面
                next({
                    path: '/',
                });
            } else if (to.matched.some((res) => res.meta.requireAuth)) {
                // 判断是否需要登录权限
                if (store.state.userdata) {
                    
                    next();
                } else {
                    // 没登录则跳转到登录界面
                    next({
                        path: '/sign-in',
                        query: { redirect: to.fullPath },
                    });
                }
            } else {
                next();
            }
        }
    });
    return router;
}

export default {
    getRouter,
};
