import Vue from "vue";
import App from "./App.vue";
import route_replace from "./router";
import i18n from "./lang";
import api from './api/request'
import store from './store';
import vuetify from "@/plugins/vuetify";
import "@/assets/scss/_global.scss";
import Toast from './components/Toast' //弹窗组件
import createToast from './plugins/create' //弹窗组件
import * as Filters from './plugins/filter' //过滤器
import PublicMethods from './plugins/until' //全局公用方法
import Meta from "vue-meta"
Vue.config.productionTip = false;


// plugin svgrender 
// import Vue plugin
import VueSvgInlinePlugin from "vue-svg-inline-plugin";

// import polyfills for IE if you want to support it
import "vue-svg-inline-plugin/src/polyfills";
// use Vue plugin without options
Vue.use(VueSvgInlinePlugin);
// use Vue plugin with options
VueSvgInlinePlugin.install(Vue, {
  attributes: {
    data: ["src"],
    remove: ["alt"]
  }
});
// end:: svgrender 
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
Vue.component(VueSlickCarousel)
Vue.component("VueSlickCarousel", VueSlickCarousel);
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
//封装axios
Vue.prototype.$api = api;
//公共方法类
Vue.prototype.$PublicMethods = PublicMethods;
Vue.component('apexchart', VueApexCharts)
Vue.use(Meta);

Vue.prototype.$Toast = ((obj) => {
  return createToast(Toast, obj).show();
})

//注册全局过滤器
Object.keys(Filters).forEach(key => {
  Vue.filter(key, Filters[key])
})
//初始化vue
function initView() {
  new Vue({
    router: route_replace.getRouter(),
    vuetify: vuetify.changePrimary(),
    store,
    i18n: i18n.getI18n(),
    render: h => h(App)
  }).$mount("#app");
}
//获取基础配置
function getConfig() {
  api.public.webMsg({}).then((res) => {
    if (res.status == 1) {
      let jsondata = res.data_new;
      store.commit("setconfig", jsondata);
      if (store.state.statedeflang == null) {
        jsondata.lang.map(item => {
          if (item.is_def == 1) {
            store.commit("setdeflang", item);
          }
        })
        LangPack();
      }else{
        initView();
      }
    }
  });
}
function LangPack() {
  api.public.getLangPack().then(res => {
    if (res.status == 1) {
      store.commit("setLangJson", res.data)
      initView();
    }
  })
}
if (store.state.webHost == '' || store.state.localDomain != store.state.localDomainStorage) {
  api.public.getbaseMsg({ webdomain: store.state.localDomain }).then(res => {
    if (res.status == 1) {
      store.commit("setLangJson",null)
      store.commit("setdeflang",null)
      store.commit('setrouterdomain_url', res.data.web_host);
      store.commit('setwebHost', res.data.domain_url);
      store.commit('setlocalDomainStorage', store.state.localDomain);
      getConfig();
    }
  })
} else {
  if (localStorage.getItem("webHost")=='flowersworld.vip') {
    store.commit('setwebHost', 'flowersworld.zzqss.shop');
  }
  getConfig();
}
