//所有过滤器方法
import store from '../store';
import PublicMethods from './until' //过滤器
let webhost_=store.state.webHost;
//货币过滤
function rateFilter(price, showsymbol_=1) {
  let rate = store.state.statedefrate?store.state.statedefrate.rate:1;
  const num =store.state.statedefrate?store.state.statedefrate.keep_decimals:2;
  const is_front_ =store.state.statedefrate?store.state.statedefrate.is_front:1;
  const symbol_ =store.state.statedefrate?store.state.statedefrate.symbol:'$';
  if (price == "") {
    price = 0;
  }

  if (typeof price === 'string') {
    price = parseFloat(price);
  }
  let total = price * rate;

  total = PublicMethods.roundData(total, num);

  total =  PublicMethods.moneyFormat(total);
  total = String(total).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  let returncontent='';
  if(showsymbol_===1){
    if(is_front_==='1'){

      returncontent=symbol_+''+total
    }else{
      returncontent=total+''+symbol_
  
    }
  }else{
    returncontent=total
  }
  
  
  return returncontent;
}


//时间戳转换
function formFilter(timestamp, n) {
  var date = new Date(timestamp * 1000) // 时间戳为10位需*3000，时间戳为13位的话不需乘3000
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  if (n) {
    return Y + M + D
  } else {
    return Y + M + D + h + m + s
  }
}
//时间戳转换
function countDown(val) {
  // 参数为秒为单位
  let totalTime = val;
  let day = parseInt(totalTime / (24 * 60 * 60)); // 计算整数天数
  let afterDay = totalTime - day * 24 * 60 * 60; // 取得算出天数后剩余的秒数
  let hour =
    parseInt(afterDay / (60 * 60)) > 9
      ? parseInt(afterDay / (60 * 60))
      : "0" + parseInt(afterDay / (60 * 60)); // 计算整数小时数
  let afterHour = totalTime - day * 24 * 60 * 60 - hour * 60 * 60; // 取得算出小时数后剩余的秒数
  let min =
    parseInt(afterHour / 60) > 9
      ? parseInt(afterHour / 60)
      : "0" + parseInt(afterHour / 60); // 计算整数分
  let afterMin =
    parseInt(totalTime - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60) >9
      ? parseInt(totalTime - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60)
      : "0" +
        parseInt(
          totalTime - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60
        ); 
  // 取得算出分后剩余的秒数
  let lastTime = day + "天" + hour + "时" + min + "分" + afterMin+ "秒";
  return lastTime;
}

  // 定义全局的货币过滤器 支付货币
  function priceRateZhu(price,only=1) {
    //only=1 返回带货币符号  only=2 不返回货币符号
    let symbol=store.state.config?store.state.config.othermentCurrencySymbol.value:'円';//货币符号
    if(price == ""||price==null){
      price = 0;
    }
    if (typeof price === 'string') {
      price = parseFloat(price);
    }
    if(only==1){
      if(store.state.router_domain_url=="esenmart.zzqss.shop"){
        return symbol+price
      }else{
        return price+symbol
      }
        
    }else{
      return price
    }
  }
  // 定义全局的货币过滤器 当地货币 
  function priceRateLocal(price, rate = 1) {
    rate = store.state.config.huilv.value;//汇率
    if((webhost_=='gobuy.zzqss.shop'|| webhost_=='riripai.zzqss.shop')&&store.state.userdata){
      rate = store.state.userdata.huilv;//汇率
    }
    let symbol=store.state.config.paymentCurrencySymbol.value;//货币符号
    let nums=store.state.config.decimal_rm.value;//保留小数位
    if(price == ""){
      price = 0;
    }
    if (typeof price === 'string') {
      price = parseFloat(price);
    }
    let total = price * rate;
    
    total = PublicMethods.roundData(total,nums);
    // 当地货币显示
    if(store.state.router_domain_url=="esenmart.zzqss.shop"){
      return "(≈"+symbol+total+")";
    }else{
      return "(≈"+total+symbol+")";
    }
  }
// 定义wakuwaku全局的货币过滤器 当地货币 
function priceRateWaku(price) {
  let symbol=store.state.config.paymentCurrencySymbol.value;//货币符号
  let nums=store.state.config.decimal_rm.value;//保留小数位
  if(price == ""){
    price = 0;
  }
  if (typeof price === 'string') {
    price = parseFloat(price);
  }
  price=PublicMethods.roundData(price,nums)
    return "(≈"+price+symbol+")";
  
}
  // 筛选不带有≈ ，不要删
  function priceRateLocalequal(price, rate = 1) {
    rate = store.state.config.huilv.value;//汇率
    if(webhost_=='gobuy.zzqss.shop'&&store.state.userdata){
      rate = store.state.userdata.huilv;//汇率
    }
    let symbol=store.state.config.paymentCurrencySymbol.value;//货币符号
    let nums=store.state.config.decimal_rm.value;//保留小数位
    if(price == ""){
      price = 0;
    }
    if (typeof price === 'string') {
      price = parseFloat(price);
    }
    let total = price * rate;
    
    total = PublicMethods.roundData(total,nums);
    return total+symbol;
  }
  // 商品价格货币
  function priceRateGoods(price){
    if(price == ""||price == null){
      price = 0;
    }
    
      if(webhost_=='gobuy.zzqss.shop'){
        return Math.round(price)+' 日元';
      }else{
        return  Math.round(price)+'円';
      }
  }

export{
    rateFilter,
    formFilter,
    countDown,
    priceRateLocal,
    priceRateZhu,
    priceRateGoods,
    priceRateLocalequal,
    priceRateWaku
}