import axios from 'axios'
import qs from 'qs'
import store from '../store'
import router from '../router'

const DefaultParam = {
  repeatable: false
};
let prefixUrl= store.state.apiurl;  // 默认168服务器
if (store.state.localDomain=='jpweb.bid.fans'|| store.state.localDomain=='esenmart.com' || store.state.localDomain=='ezbidbuy.com') {
  prefixUrl=store.state.otherapiurl;
}
if (store.state.localDomain=='gsritao.com'|| store.state.localDomain=='www.gsritao.com' || store.state.localDomain=='riya.xin' || store.state.localDomain=='wakuwaku.bar'  || store.state.localDomain=='ripaigo.com' || store.state.localDomain=='croxx.zzqss.com' || store.state.localDomain=='wanshiwu.jp' || store.state.localDomain == 'renkongjian.zzqss.com') {
  prefixUrl=store.state.xgapiurl;  //香港一
}
if (store.state.localDomain=='jpxu.zzqss.com' || store.state.localDomain=='jpware.com.hk' || store.state.localDomain=='japan51buy.zzqss.com' || store.state.localDomain=='meilu.jp' || store.state.localDomain=='www.meilu.jp') {
  prefixUrl=store.state.xgTwoapiurl; //香港二
}
if (store.state.localDomain=='ripaigo.com' || store.state.localDomain=='ripaigo.zzqss.com') {
  prefixUrl=store.state.ripaigoapiurl;
}
if (store.state.localDomain=='gobuy.zzqss.com') {
  prefixUrl=store.state.gobuyapiurl;
}
const ajax = {
  timeout: 50000, // request timeout
  PREFIX: prefixUrl,
  requestingApi: new Set(),
  getQueryString: function (url) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
    var r = window.location.search.substr(1).match(reg)
    if (r != null) return unescape(r[2]); return null
  },
  extractUrl: function (url) {
    return url ? url.split('?')[0] : ''
  },
  isRequesting: function (url) {
    const api = this.extractUrl(url)
    return this.requestingApi.has(api)
  },
  addRequest: function (url) {
    const api = this.extractUrl(url)
    this.requestingApi.add(api)
  },
  deleteRequest: function (url) {
    const api = this.extractUrl(url)
    this.requestingApi.delete(api)
  },
  get: function (url, param, extendParam) {
    const params = {
      url,
      method: 'GET',
    }
    if (param) {
      params.params = param
    }
    return this.ajax(params, extendParam)
  },
  post: function (url, param, extendParam) {
    var params = {
      url,
      method: 'POST',
    }

    if (!param) {
      const param = {}
    }
    // if (param) params.data = qs.stringify(param);
    if (param) params.data = param
    return this.ajax(params, extendParam)
  },
  postJson: function (url, paramJson, extendParam) {
    return this.ajax({
      url,
      method: 'POST',
      data: paramJson,
    }, extendParam)
  },
  patchJson: function (url, paramJson, dataType, extendParam) {
    return this.ajax({
      url,
      method: 'PATCH',
      data: paramJson,
    }, extendParam)
  },
  delete: function (url, extendParam) {
    return this.ajax({
      url: url,
      method: 'DELETE',
    }, extendParam)
  },
  ajax: function (param, extendParam) {
    const defLangId = store.state.statedeflang ? store.state.statedeflang.lang_sign : 'zh'
      const to_ken = store.state.usertoken ? store.state.usertoken.token : ''
    let params = Object.assign({}, DefaultParam, param, extendParam || {})
    params.crossDomain = params.url.indexOf('http') === 0
    let url = params.url
    if (!params.crossDomain) {
      // url = params.url = this.PREFIX + params.url;
      url = params.url = this.PREFIX + params.url
    }
    if (params.method != 'GET') {
      // if (this.isRequesting(url)) {

      //   return new Promise((resolve, reject) => {
      //     resolve({
      //       ok: false,
      //       msg: '重复请求'
      //     });
      //   });
      // }
      if (params.repeatable === false) {
        this.addRequest(url)
      }
    }
    var header = {}
    if (param.url == '/Home/get_fill_shop_category' && store.state.webHost == 'hokutosei.zzqss.shop') {
      header = {
        'Content-Type': 'application/json',
        token: to_ken,
        webHost: store.state.webHost,
        webdomain: store.state.localDomain,
        Authorization: 'bearer' + to_ken,
      }
    } else {
       header = {
        'Content-Type': 'application/json',
        token: to_ken,
        webHost: store.state.webHost,
        langsign: defLangId,
        webdomain: store.state.localDomain,
        Authorization: 'bearer' + to_ken,
      }
    }

    const defaultParam = {
      headers: header,
      responseType: 'json',
      validateStatus: function (status) {
        return true
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, {
          allowDots: true,
        })
      },
    }
    if (params.crossDomain) {
      defaultParam.headers = {}
    }
    const that = this
    params = Object.assign({}, defaultParam, params)
    return new Promise((resolve) => {
      return axios.request(params).then((response) => {
        that.deleteRequest(params.url)
        const data = response.data
        const status = response.status
        // 如果后端统一封装返回，即所有的请求都是200, 错误码由返回结果提供，则使用以下代码获取状态
        const mysttaus = data.msg
        if (mysttaus == '未登录,无法请求') {
          store.commit('setusertoken', null)
          store.commit('setuserdata', null)
          window.top.location = '/sign-in'
          return
        }
        // if (status != 200) {
        //   if (status == 401) {
        //     window.top.location = '/login';
        //     return;
        //   }
        //   if (status == 500) {
        //     this.$Toast({
        //       content: '后台异常',
        //       type: 'error'
        //     })

        //   } else if (status == 404) {
        //     this.$Toast({
        //       content: '请求不存在',
        //       type: 'error'
        //     })

        //   } else if (status != 200) {
        //     this.$Toast({
        //       content: data._msg || '请求异常',
        //       type: 'error'
        //     })
        //   }
        // }
        data.ok = data.status == 200
        resolve(data)
      }).catch(() => {
        that.deleteRequest(params.url)
        resolve({
          ok: false,
        })
      })
    })
  },
}
export default ajax
